import { Component, OnInit } from '@angular/core';
import { doc } from 'rxfire/firestore';
import { firestore, initializeApp } from 'firebase';
import 'firebase/firestore';
import { map } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit {

  private devicesCollection: AngularFirestoreCollection<any>;
  private devices: Observable<any>;
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) {
  }

  ngOnInit() {
    this.afAuth.authState.subscribe((user) => {
      console.log(user);
      this.devicesCollection = this.db.collection('devices/' + user.uid + '/devices');
      this.devices = this.devicesCollection.valueChanges();
      this.devices.subscribe((snap) => {
        console.log(snap);
      });
    //   const devicesDoc = db.doc('devices/' + user.uid);
    //   doc(devicesDoc).subscribe(snapshot => {
    //     console.log('log snapshot:');
    //     console.log(snapshot.id);
    //     console.log(snapshot.data());
    //   })
    });
  }

  addItem(device: any) {
    this.devicesCollection.add(device);
  }

  onButtonClick() {
    console.log('Requesting any Bluetooth Device...');
    navigator['bluetooth'].requestDevice({
     // filters: [...] <- Prefer filters to save energy & show relevant devices.
        acceptAllDevices: true,
        optionalServices: ['generic_access']})
    .then(device => {
      console.log(device);
      this.addItem(JSON.stringify(device));
      console.log('Connecting to GATT Server...');
      return device.gatt.connect();
    })
    .then(server => {
      console.log('Getting GAP Service...');
      return server.getPrimaryService('generic_access');
    })
    .then(service => {
      console.log('Getting GAP Characteristics...');
      return service.getCharacteristics();
    })
    .then(characteristics => {
      let queue = Promise.resolve();
      characteristics.forEach(characteristic => {
        console.log(characteristic);
      });
      return queue;
    })
    .catch(error => {
      console.log('Argh! ' + error);
    });
  }

}
