import { Configuration } from './models/configuration.model';
import * as SelectedDefinitionActions from './selected.action';
export type Action = SelectedDefinitionActions.All;

/// Helper function to create new state object
const newState = (state, newData) => {
  return Object.assign({}, state, newData);
};

const def: Configuration = new Configuration('New Configuration');

export function selectedDefinitionReducer(
  state: Configuration = def,
  action: Action
) {
  switch (action.type) {
    case SelectedDefinitionActions.SELECT_DEFINITION:
      return newState({}, action.payload);
    case SelectedDefinitionActions.SELECT_DEFINITION:
      return newState(state, action.payload);
    default:
      return state;
  }
}
