import { Action } from '@ngrx/store';
import { Configuration } from './models/configuration.model';
import * as SelectedDefinitionActions from './selected.action';

export const SELECT_DEFINITION = '[Select] Selected Definition';
export const UPDATE_DEFINITION = '[Update] Selected Definition';
// export const UPVOTE = '[Post] Upvote';
// export const DOWNVOTE = '[Post] Downvote';
// export const RESET = '[Post] Reset';

export class SelectDefinition implements Action {
  readonly type = SELECT_DEFINITION;

  /// user a constructor to send a payload with the action
  constructor(public payload: Configuration) {}
}

export class UpdateDefinition implements Action {
  readonly type = UPDATE_DEFINITION;
  /// user a constructor to send a payload with the action
  constructor(public payload: Configuration) {}
}

// export class Downvote implements Action {
//   readonly type = DOWNVOTE;
// }

// export class Reset implements Action {
//   readonly type = RESET;
// }

export type All = SelectDefinition | UpdateDefinition;
// | Reset
// | EditText;
