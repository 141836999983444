import { AdvertisementView } from './advertisement-view.model';
import { Gatt } from './gatt.model';
import { UUID } from 'angular2-uuid';

export enum Format {
  Ascii = 1,
  LittleEndianMacAddress,
  MacAddress,
  DecimalInteger,
  DecimalPercentage
}
export class Configuration {
  constructor(_name: string) {
    this.id = UUID.UUID();
    this.name = _name;
    this.advertisements = [];
    this.gatt = null;
    this.filterCriteria = {
      macAddressSubstring: [],
      service: [],
      advertisingDataSubstring: []
    };
    this.gattServer = [];
    this.configurations = [];
  }
  id: string;
  name: string;
  advertisements: Array<AdvertisementView>;
  gatt: Gatt;
  filterCriteria: {
    macAddressSubstring: Array<{
      index: number;
      str: string;
    }>;
    service: Array<String>;
    advertisingDataSubstring: Array<{
      index: number;
      str: string;
    }>;
  };
  gattServer: Array<{
    serviceUuid: string;
    serviceName: string;
    characteristicName: string;
    characteristicUuid: string;
    format: Format;
  }>;
  configurations: Array<{
    name: string;
    service: string;
    characteristic: string;
    value: string;
  }>;
}
