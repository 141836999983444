import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private afAuth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(this.firebaseAuthChangeListener);

  }


private firebaseAuthChangeListener(response) {
    // if needed, do a redirect in here
    if (response) {
      console.log('Logged in :)');
    } else {
      console.log('Logged out :(');
    }
  }

  public successCallback(event: any) {
    // this.afAuth.auth.updateCurrentUser(event.authResult.user);
    // console.log(this.afAuth.auth.currentUser);
    this.router.navigate(['/']);
    // this.afAuth.auth.currentUser = event.authResult.user;
  }

}
