import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit {

  ngOnInit() {
  }

  constructor(private breakpointObserver: BreakpointObserver,
    private afAuth: AngularFireAuth,
    private router: Router) {}

    public signout() {
      this.afAuth.auth.signOut();
      this.router.navigate(['/login']);
    }

}
