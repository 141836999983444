import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Configuration, Format } from '../models/configuration.model';
import * as SelectedDefinitionActions from '../selected.action';
import { Observable } from 'rxjs';
import {
  Validators,
  FormGroup,
  FormArray,
  FormBuilder,
  NgForm
} from '@angular/forms';
import { ConfigurationService } from '../configuration.service';

@Component({
  selector: 'app-definition',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  public myForm: FormGroup; // our form model
  public selectedDefinition: Observable<Configuration>;
  public def$: any;
  private globalVm;
  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private _store: Store<any>,
    private _router: Router,
    private _fb: FormBuilder,
    private _configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.selectedDefinition = this._store.select('state');
    this.selectedDefinition.subscribe(snap => {
      console.log('log selected definition');
      console.log(snap);
      // this.createStackblitz();
      // this.myForm = this._fb.group({
      //   name: [snap.name, [Validators.required, Validators.minLength(5)]],
      //   macAddressSubstring: this._fb.array([this.initMacAddressSubstring()])
      // });
    });
    this.myForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      macAddressSubstring: this._fb.array([this.initMacAddressSubstring()]),
      advertisingDataSubstring: this._fb.array([
        this.initAdvertisingDataSubstring()
      ]),
      configurations: this._fb.array([this.initConfigurations()]),
      service: this._fb.array([this.initService()])
    });
  }

  saveDefinition() {
    console.log(this.myForm.value);
    // call API to save definition
    const name = this.myForm.value.name;
    const macAddressSubstring = this.myForm.value.macAddressSubstring;
    const advertisingDataSubstring = this.myForm.value.advertisingDataSubstring;
    const service = this.myForm.value.service;

    this.selectedDefinition.subscribe(def => {
      def.filterCriteria.macAddressSubstring = macAddressSubstring;
      def.filterCriteria.advertisingDataSubstring = advertisingDataSubstring;
      def.filterCriteria.service = service.map(ser => {
        return ser.string;
      });
      def.name = name;

      this._configurationService
        .updateDefinition(def.id, def)
        .subscribe(res => {
          this._store.dispatch(
            new SelectedDefinitionActions.UpdateDefinition(def)
          );
        });
      console.log(def);
    });
  }

  initConfigurations() {
    return this._fb.group({
      name: [''],
      service: [''],
      characteristic: [''],
      value: ['']
    });
  }

  addConfigurations() {
    const control = <FormArray>this.myForm.controls['configurations'];
    control.push(this.initConfigurations());
  }

  removeConfigurations(i: number) {
    const control = <FormArray>this.myForm.controls['configurations'];
    control.removeAt(i);
  }

  initService() {
    return this._fb.group({
      string: ['']
    });
  }

  addService() {
    const control = <FormArray>this.myForm.controls['service'];
    control.push(this.initService());
  }

  removeService(i: number) {
    // remove address from the list
    const control = <FormArray>this.myForm.controls['service'];
    control.removeAt(i);
  }

  initMacAddressSubstring() {
    return this._fb.group({
      index: [0],
      string: ['']
    });
  }

  addMacAddressSubstring() {
    // add address to the list
    const control = <FormArray>this.myForm.controls['macAddressSubstring'];
    control.push(this.initMacAddressSubstring());
  }

  removeMacAddressSubstring(i: number) {
    // remove address from the list
    const control = <FormArray>this.myForm.controls['macAddressSubstring'];
    control.removeAt(i);
  }

  initAdvertisingDataSubstring() {
    return this._fb.group({
      index: [0],
      string: ['']
    });
  }

  addAdvertisingDataSubstring() {
    // add address to the list
    const control = <FormArray>this.myForm.controls['advertisingDataSubstring'];
    control.push(this.initAdvertisingDataSubstring());
  }

  removeAdvertisingDataSubstring(i: number) {
    // remove address from the list
    const control = <FormArray>this.myForm.controls['advertisingDataSubstring'];
    control.removeAt(i);
  }

  // filter advertisements by: mac address rule OR contains service UUID
  // default display: raw bytes and advertising data
  // configure based on these rules: [rules]
  // set SERVICE, CHARACTERISTIC to VALUE (bytes)

  logFunction() {
    console.log('testing this:');
    this.globalVm.getFsSnapshot().then(snap => {
      console.log(snap);
    });
  }
}
