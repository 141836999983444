import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from './models/configuration.model';
import * as SelectedDefinitionActions from './selected.action';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements OnInit {
  private definitionsCollection: AngularFirestoreCollection<any>;
  private definitions: Observable<Configuration[]>;
  private definitionDoc: AngularFirestoreDocument<Configuration>;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private _store: Store<any>
  ) {}

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      console.log(user);
      this.definitionsCollection = this.db.collection(
        'definitions/' + user.uid + '/definitions'
      );
      this.definitions = this.definitionsCollection.valueChanges();
      this.definitions.subscribe(snap => {
        console.log(snap);
      });
    });
  }

  /**
   * Add a new Definition. This function dispatches an action to
   * the ngrx/store and updates the Firebase db.
   * @param value the new Definition
   */
  addDefinition(newDef: Configuration) {
    this.definitionsCollection.add(newDef);
  }

  deleteDefinition(id: string) {
    this.definitionsCollection.doc(id).delete();
  }

  // updateDefinition(id: string, newDef: Definition) {
  //   const data = JSON.parse(JSON.stringify(newDef));
  //   this.afAuth.authState.subscribe(user => {
  //     this.definitionsCollection = this.db.collection(
  //       'definitions/' + user.uid + '/definitions'
  //     );
  //     return this.definitionsCollection.doc(id).update(data);
  //   });
  //   return new Promise(function() {});
  // }
  updateDefinition(id: string, newDef: Configuration) {
    const data = JSON.parse(JSON.stringify(newDef));
    return this.afAuth.authState.pipe(
      map(user => {
        this.definitionsCollection = this.db.collection(
          'definitions/' + user.uid + '/definitions'
        );
        return this.definitionsCollection.doc(id).update(data);
      })
    );
  }
}
