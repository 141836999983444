import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Configuration } from '../models/configuration.model';
import { UUID } from 'angular2-uuid';
import { Router } from '@angular/router';
import * as selectedConfigurationActions from '../selected.action';

@Component({
  selector: 'app-definitions',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {
  private definitionsCollection: AngularFirestoreCollection<Configuration>;
  private configurations: Observable<Configuration[]>;
  private selectedConfiguration: Observable<Configuration>;
  private chosenDefinition$: Observable<Configuration>;
  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private _store: Store<any>,
    private _router: Router
  ) {}

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      console.log(user);
      this.definitionsCollection = this.db.collection(
        'configurations/' + user.uid + '/configurations'
      );
      this.configurations = this.definitionsCollection.valueChanges();
      this.selectedConfiguration = this._store.select('state');

      const def: Configuration = new Configuration('New Configuration');
      this.selectedConfiguration.subscribe(snap => {
        console.log('log selected configuration');
        console.log(snap);
      });
      this.configurations.subscribe(snap => {
        console.log(snap);
      });
    });
  }

  addConfiguration(value) {
    const def: Configuration = new Configuration('New Configuration');
  }

  updateSelectedConfiguration(newDef: Configuration) {
    this._store.dispatch(
      new selectedConfigurationActions.SelectDefinition(newDef)
    );
  }

  selectConfiguration(evt) {
    console.log(evt);
    this.updateSelectedConfiguration(evt);
    this._router.navigate(['/configuration']);
  }

  createNewConfiguration() {
    const def: Configuration = new Configuration('New Configuration');
    const data = JSON.parse(JSON.stringify(def));
    this.definitionsCollection.doc(def.id).set(data);
    this.updateSelectedConfiguration(def);
    this._router.navigate(['/configuration']);
  }
}
